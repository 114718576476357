<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0 section-lg">
        <div class="shape shape-style-1 shape-default">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0 pb-100">
            <h1 class="display-3 text-white">{{ $t("stats.title") }}</h1>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section pt-lg-0 mt--100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <card class="border-0" shadow>
              <h2 class="display-3">{{ $t('stats.currentepoch.title') }}</h2>
              <div class="row align-items-center">
                <div class="col-md-9">
                  <div class="row mb-3">
                    <div class="col-12 col-md-6 mt-3">
                      <span>{{ $t('stats.currentepoch.newstake') }}</span>
                      <span :class="'stat-number text-' + (validator.properties.external_stake_accepted ? 'success' : 'danger')">{{ validator.properties.external_stake_accepted ? $t('general.allowed') : $t('general.denied') }}</span>
                    </div>
                    <div class="col-12 col-md-6 mt-3">
                      <span>{{ $t('stats.currentepoch.stakevolume') }}</span>
                      <span class="stat-number">{{ (validator.stake.value / 1000000000000000000).toFixed(0) }} XRD</span>
                    </div>
                    <div class="col-6 col-md-6 mt-3">
                      <span>{{ $t('stats.currentepoch.proposals.completed') }}</span>
                      <span class="stat-number">{{ validator.info.uptime.proposals_completed }}</span>
                    </div>
                    <div class="col-6 col-md-6 mt-3">
                      <span>{{ $t('stats.currentepoch.proposals.missed') }}</span>
                      <span class="stat-number">{{ validator.info.uptime.proposals_missed }}</span>
                    </div>
                    <div class="col-6 col-md-4 mt-3">
                      <span>{{ $t('stats.currentepoch.validatorposition') }}</span>
                      <span class="stat-number"># {{ validatorPosition + 1 }}</span>
                    </div>
                    <div class="col-6 col-md-4 mt-3">
                      <span>{{ $t('stats.currentepoch.validatorshare') }}</span>
                      <span class="stat-number">{{ (validatorShare * 100).toFixed(2) }}%</span>
                    </div>
                    <div class="col-6 col-md-4 mt-3">
                      <span>{{ $t('stats.currentepoch.validatorfee') }}</span>
                      <span class="stat-number">{{ validator.properties.validator_fee_percentage }}%</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="px-3">
                  <CircularProgress
                    :transitionDuration="2500"
                    :strokeWidth="10"
                    :strokeColor="uptime ? '#2dce89' : '#bbb'"
                    :value="validator ? validator.info.uptime.uptime_percentage : null"
                    :noDataText="$t('general.no-data')"
                  >
                </CircularProgress>
                <p class="text-center font-weight-bold">{{ $t('general.uptime') }}</p>
                </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
    <section class="section section">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-lg-6">
            <card class="border-0" hover shadow>
              <h2 class="text-center display-3">Validator node</h2>
              <div role="alert" class="d-flex justify-content-around alert alert-default">
                <Flag code="NL" size="L" gradient="real-linear" :hasDropShadow="true" />
                <div style="line-height: 24px; font-size: 150%">{{ $t('general.located_in') }} <strong>Amsterdam</strong></div>
              </div>
              <div class="py-2" style="max-width: 250px; margin: auto;">
                <CircularProgress
                  :transitionDuration="2500"
                  :strokeWidth="10"
                  :strokeColor="uptime ? '#2dce89' : '#bbb'"
                  :value="uptime ? (uptime['today']['radixnode1'] * 100).toFixed(2) : null"
                  :noDataText="$t('general.no-data')"
                >
                </CircularProgress>
                </div>
                <p class="text-center font-weight-bold" style="font-size: 150%">{{ $t('general.uptime') }}</p>
            </card>
          </div>
          <div class="col-lg-6">
            <card class="border-0" hover shadow>
              <h2 class="text-center display-3">Backup node</h2>
              <div role="alert" class="d-flex justify-content-around alert alert-default">
                <Flag code="DE" size="L" gradient="real-linear" :hasDropShadow="true" />
                <div style="line-height: 24px; font-size: 150%">{{ $t('general.located_in') }} <strong>Frankfurt</strong></div>
              </div>
              <div class="py-2" style="max-width: 250px; margin: auto;">
                <CircularProgress
                  :transitionDuration="2500"
                  :strokeWidth="10"
                  :strokeColor="uptime ? '#2dce89' : '#bbb'"
                  :value="uptime ? (uptime['today']['radixnode2'] * 100).toFixed(2) : null"
                  :noDataText="$t('general.no-data')"
                >
                </CircularProgress>
                </div>
                <p class="text-center font-weight-bold" style="font-size: 150%">{{ $t('general.uptime') }}</p>
            </card>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <card class="border-0" shadow>
              <h2 class="display-3">{{ $t('stats.uptime_30_days') }}</h2>
              <div>
                <h3>Validator node {{ uptime ? '(' + (parseFloat(uptime['30days']['radixnode1']) * 100).toFixed(2) + '%)' : '' }}</h3>
                <uptime-bars :uptimeData="daily30days['radixnode1']" />
              </div>
              <div>
                <h3 class="pt-3">Backup node {{ uptime ? '(' + (parseFloat(uptime['30days']['radixnode2']) * 100).toFixed(2) + '%)' : '' }}</h3>
                <uptime-bars :uptimeData="daily30days['radixnode2']" />
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UptimeBars from "@/components/UptimeBars";
import CircularProgress from "@/components/CircularProgress";

import { mapGetters } from 'vuex';

export default {
  name: "stats",
  components: {
    UptimeBars,
    CircularProgress,
  },
  computed: {
    ...mapGetters(['validator', 'validatorPosition', 'validatorShare']),
    uptime() {
      return this.$store.state.uptime;
    },
    daily30days() {
      var nullArray = [];
      for (var i = 0; i < 30; i++) {
        nullArray.push(null);
      }
      var metrics = {
        'radixnode1': nullArray,
        'radixnode2': nullArray,
      };
      if (this.uptime && this.uptime['30days_daily']) {
        var data = this.uptime['30days_daily']
        Object.keys(data).forEach(function(key) {
          var element = data[key];
          var values = [];
          for (var i = 30; i > element.length; i--) {
            values.push(null);
          }
          for (var i = 0; i < element.length; i++) {
            values.push(element[i][1])
          }
          metrics[key] = values;
        });
      }
      return metrics;
    }
  },
  mounted() {
    this.$store.dispatch('getUptime');
    this.$store.dispatch('getValidatorSet');
  },
};
</script>
