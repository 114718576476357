<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <h1 class="display-3 text-white">{{ $t("home.title") }}</h1>
            <p class="lead text-white">
              {{ $t('home.title_text') }}
            </p>
            <div class="btn-wrapper">
              <base-button
                tag="a"
                href="#staking"
                class="mb-3 mb-sm-0"
                type="accent"
                icon="fas fa-coins"
              >
                {{ $t('home.start_staking') }}
              </base-button>
              <router-link to="/stats" custom v-slot="{ href, navigate }">
                <base-button
                  tag="a"
                  :href="href"
                  class="mb-3 mb-sm-0"
                  type="info"
                  icon="fas fa-chart-bar"
                  @click="navigate"
                >
                  {{ $t('home.view_stats') }}
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section section-lg pt-lg-0 mt--200">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon
                    name="fas fa-shield-alt"
                    type="primary"
                    rounded
                    class="mb-4"
                  >
                  </icon>
                  <h3 class="text-primary">{{ $t('home.usp.secure.title') }}</h3>
                  <p class="description mt-3">
                    {{ $t('home.usp.secure.description') }}
                  </p>
                  <div>
                    <badge type="primary" rounded>{{ $t('home.usp.secure.tag1') }}</badge>
                    <badge type="primary" rounded>{{ $t('home.usp.secure.tag2') }}</badge>
                    <badge type="primary" rounded>{{ $t('home.usp.secure.tag3') }}</badge>
                  </div>
                  <base-button tag="a" href="#secure" type="primary" class="mt-4">
                    {{ $t('general.learn-more') }}
                  </base-button>
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon
                    name="fas fa-leaf"
                    type="success"
                    rounded
                    class="mb-4"
                  >
                  </icon>
                  <h3 class="text-success">{{ $t('home.usp.eco.title') }}</h3>
                  <p class="description mt-3">
                    {{ $t('home.usp.eco.description') }}
                  </p>
                  <div>
                    <badge type="success" rounded>{{ $t('home.usp.eco.tag1') }}</badge>
                    <badge type="success" rounded>{{ $t('home.usp.eco.tag2') }}</badge>
                  </div>
                  <base-button tag="a" href="#eco" type="success" class="mt-4">
                    {{ $t('general.learn-more') }}
                  </base-button>
                </card>
              </div>
              <div class="col-lg-4">
                <card class="border-0" hover shadow body-classes="py-5">
                  <icon name="fas fa-users" type="warning" rounded class="mb-4">
                  </icon>
                  <h3 class="text-warning">{{ $t('home.usp.community.title') }}</h3>
                  <p class="description mt-3">
                    {{ $t('home.usp.community.description') }}
                  </p>
                  <div>
                    <badge type="warning" rounded>{{ $t('home.usp.community.tag1') }}</badge>
                    <badge type="warning" rounded>{{ $t('home.usp.community.tag2') }}</badge>
                    <badge type="warning" rounded>{{ $t('home.usp.community.tag3') }}</badge>
                  </div>
                  <base-button tag="a" href="#community" type="warning" class="mt-4">
                    {{ $t('general.learn-more') }}
                  </base-button>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-12">
            <card class="border-0 bg-gradient-primary text-white floating mt--100" shadow>
              <h2 id="staking" class="text-center display-3 text-white">{{ $t('staking.guide.title') }}</h2>
                <ul class="list-unstyled mt-5">
                  <li class="py-2">
                      <div class="d-flex align-items-center w-100">
                          <badge type="info" circle class="mr-3" icon="fas fa-copy"></badge>
                          <div class="w-100">
                            <h4 class="mb-0 text-white">{{ $t('staking.guide.step1') }}</h4>
                            <input type="hidden" id="validator-address" :value="validatorAddress">
                            <div class="input-group w-100" id="copyValidatorAddress">
                              <base-input :value="validatorAddress" class="w-75" alternative readonly></base-input>
                              <div class="input-group-append w-25">
                                <base-button type="success" class="form-control" size="lg" @click.stop.prevent="copyValidatorAddress">{{ $t('general.copy') }}</base-button>
                              </div>
                            </div>
                            <b-tooltip ref="validatorTooltip" triggers="" target="copyValidatorAddress">{{ $t('staking.guide.validator_copied') }}</b-tooltip>
                          </div>
                      </div>
                  </li>
                  <li class="py-2">
                      <div class="d-flex align-items-center">
                          <badge type="info" circle class="mr-3" icon="fas fa-wallet"></badge>
                          <h4 class="mb-0 text-white">{{ $t('staking.guide.step2') }}</h4>
                      </div>
                  </li>
                  <li class="py-2">
                      <div class="d-flex align-items-center">
                          <badge type="info" circle class="mr-3" icon="fas fa-paste"></badge>
                          <h4 class="mb-0 text-white">{{ $t('staking.guide.step3') }}</h4>
                      </div>
                  </li>
                  <li class="py-2">
                      <div class="d-flex align-items-center">
                          <badge type="info" circle class="mr-3" icon="fas fa-coins"></badge>
                          <h4 class="mb-0 text-white">{{ $t('staking.guide.step4') }}</h4>
                      </div>
                  </li>
                  <li class="py-2">
                      <div class="d-flex align-items-center">
                          <badge type="info" circle class="mr-3" icon="fas fa-unlock"></badge>
                          <h4 class="mb-0 text-white">{{ $t('staking.guide.step5') }}</h4>
                      </div>
                  </li>
              </ul>
            </card>
            <card class="border-0 mt-5" shadow>
              <div class="row">
                <div class="col-12 col-md-10 offset-md-1 text-center">
                  <h3 class="display-3 text-center">{{ $t('staking.guide.pdf.title') }}</h3>
                  <p>{{ $t('staking.guide.pdf.text1') }}</p>
                  <p>{{ $t('staking.guide.pdf.text2') }}</p>
                  <p>{{ $t('staking.guide.pdf.text3') }}</p>
                  <base-button
                    tag="a"
                    :href="$t('staking.guide.pdf.url')"
                    class="mb-3 mb-sm-0"
                    type="accent"
                    icon="fas fa-download"
                    download
                  >
                    {{ $t('staking.guide.pdf.download') }}
                  </base-button>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
            <section class="section section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-1 bg-gradient-primary shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container py-0">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-lg-2 ml-lg-auto">
                        <div class="position-relative pl-md-5">
                            <SharingSvg />
                        </div>
                    </div>
                    <div class="col-lg-6 order-lg-1">
                        <div class="d-flex px-3">
                            <div>
                                <icon name="fas fa-divide" size="lg" class="bg-gradient-white" color="primary" shadow
                                      rounded></icon>
                            </div>
                            <div class="pl-4">
                              <h3 class="display-3 text-white">{{ $t('home.partners.title') }}</h3>
                              <p class="text-white">{{ $t('home.partners.text1') }}</p>
                              <p class="text-white">{{ $t('home.partners.text2') }}</p>
                            </div>
                        </div>
                        <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="fas fa-check-circle" gradient="warning" color="white" shadow
                                          rounded></icon>
                                </div>
                                <div>
                                    <h5 class="pl-4 title text-warning">{{ $t('home.partners.radix-staking-club.title') }}</h5>
                                    <p class="pl-4">{{ $t('home.partners.radix-staking-club.text1') }}</p>
                                    <ul>
                                      <li>{{ $t('home.partners.radix-staking-club.usp1') }}</li>
                                      <li>{{ $t('home.partners.radix-staking-club.usp2') }}</li>
                                      <li>{{ $t('home.partners.radix-staking-club.usp3') }}</li>
                                      <li>{{ $t('home.partners.radix-staking-club.usp4') }}</li>
                                      <li>{{ $t('home.partners.radix-staking-club.usp5') }}</li>
                                    </ul>
                                    <a href="https://radix-staking.club/" target="_blank" class="pl-4 text-warning">{{ $t('general.visit-website') }}</a>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </section>
    <section class="section" id="secure">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6">
            <div class="card bg-primary shadow border-0">
              <img v-lazy="'img/usp/secure.jpg'" class="card-img-top">
              <blockquote class="card-blockquote">
                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                      class="svg-bg">
                    <polygon points="0,52 583,95 0,95" class="fill-primary"></polygon>
                    <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-primary"></polygon>
                </svg>
                <h4 class="display-3 font-weight-bold text-white">{{ $t('home.usp.secure.caption') }}</h4>
              </blockquote>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pl-md-5">
              <icon name="fas fa-shield-alt" class="mb-4" size="lg" type="primary" shadow rounded></icon>
              <h3 class="text-primary">{{ $t('home.usp.secure.title') }}</h3>
              <p class="lead">{{ $t('home.usp.secure.text1') }}</p>
              <p>{{ $t('home.usp.secure.text2') }}</p>
              <p>{{ $t('home.usp.secure.text3') }}</p>
              <p>{{ $t('home.usp.secure.text4') }}</p>
              <p class="font-weight-bold text-primary">{{ $t('home.usp.secure.text5') }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg-secondary" id="eco">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6 order-md-2">
            <div class="card bg-success shadow border-0">
              <img v-lazy="'img/usp/eco.jpg'" class="card-img-top">
              <blockquote class="card-blockquote">
                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                      class="svg-bg">
                    <polygon points="583,52 0,95 583,95" class="fill-success"></polygon>
                    <polygon points="583,42 0,95 -100,0 583,95" opacity=".2" class="fill-success"></polygon>
                </svg>
                <h4 class="display-3 font-weight-bold text-white">{{ $t('home.usp.eco.caption') }}</h4>
              </blockquote>
            </div>
          </div>
          <div class="col-md-6 order-md-1">
            <div class="pr-md-5">
              <icon name="fas fa-leaf" class="mb-4" size="lg" type="success" shadow rounded></icon>
              <h3 class="text-success">{{ $t('home.usp.eco.title') }}</h3>
              <p class="lead">{{ $t('home.usp.eco.text1') }}</p>
              <p>{{ $t('home.usp.eco.text2') }} </p>
              <p>{{ $t('home.usp.eco.text3') }} </p>
              <p>{{ $t('home.usp.eco.text4') }}</p>
              <p class="font-weight-bold text-success">{{ $t('home.usp.eco.text5') }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="community">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-6">
            <div class="card bg-warning shadow border-0">
              <img v-lazy="'img/usp/community.jpg'" class="card-img-top">
              <blockquote class="card-blockquote">
                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                      class="svg-bg">
                    <polygon points="0,52 583,95 0,95" class="fill-warning"></polygon>
                    <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-warning"></polygon>
                </svg>
                <h4 class="display-3 font-weight-bold text-white">{{ $t('home.usp.community.caption') }}</h4>
              </blockquote>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pl-md-5">
              <icon name="fas fa-shield-alt" class="mb-4" size="lg" type="warning" shadow rounded></icon>
              <h3 class="text-warning">{{ $t('home.usp.community.title') }}</h3>
              <p class="lead">{{ $t('home.usp.community.text1') }}</p>
              <p>{{ $t('home.usp.community.text2') }}</p>
              <p>{{ $t('home.usp.community.text3') }}</p>
              <p>{{ $t('home.usp.community.text4') }}</p>
              <p class="font-weight-bold text-warning">{{ $t('home.usp.community.text5') }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseInput from '../components/BaseInput.vue';
import BaseButton from '../components/BaseButton.vue';
import SharingSvg from '../vectors/Sharing.vue';
import { BTooltip} from 'bootstrap-vue';

export default {
  name: "home",
  components: {
    BaseInput,
    BaseButton,
    BTooltip,
    SharingSvg,
  },
  computed: {
    validatorAddress() {
      return this.$store.state.validatorAddress;
    },
  },
  data() {
    return {
      validatorCopied: false,
      uptime: {},
    }
  },
  methods: {
    copyValidatorAddress () {
      let validatorAddressCopy = document.querySelector('#validator-address')
      validatorAddressCopy.setAttribute('type', 'text')
      validatorAddressCopy.select()

      try {
        this.validatorCopied = document.execCommand('copy');
        var tooltip = this.$refs.validatorTooltip;
        tooltip.$emit('open');
        setTimeout(() => {  tooltip.$emit('close'); }, 2000);
      } catch (err) {
        alert('Oops, unable to copy');
      }

      /* unselect the range */
      validatorAddressCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  }
};
</script>
