import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    uptime: null,
    validatorSet: null,
    validatorAddress: 'rv1qgw2s2rrn5gs3tumtk4z62casy5f0ze49zutfy6t32acyc7vn00qsjkajvj',
}

const getters = {
    validator: (state) => {
        if (state.validatorSet) {
            return state.validatorSet.find(validator => validator.validator_identifier.address == state.validatorAddress);
        } else {
            return null;
        }
    },
    validatorPosition: (state) => {
        if (state.validatorSet) {
            return state.validatorSet.findIndex(validator => validator.validator_identifier.address == state.validatorAddress);
        } else {
            return 0;
        }
    },
    validatorShare: (state, getters) => {
        if (state.validatorSet == null) {
            return 0;
        }
        var totalStake = 0;
        state.validatorSet.slice(0,100).forEach(validator => {
            totalStake += parseInt(validator.stake.value);
        });
        return getters.validator.stake.value / totalStake;
    },
}

const actions = {
    getUptime({commit}) {
        axios.get('/api.php/uptime')
            .then(response => {
                commit('SET_UPTIME', response.data);
            });
    },
    getValidatorSet({commit}) {
        axios.post('https://mainnet.radixdlt.com/validators', {
            "network_identifier": {
                "network": "mainnet"
            },
        },
        {
            headers: {
                'X-Radixdlt-Target-Gw-Api': '1.0.2'
            }
        }).then(response => {
            commit('SET_VALIDATORSET', response.data.validators);
        });
    },
}

const mutations = {
    SET_UPTIME(state, uptime) {
        state.uptime = uptime;
    },
    SET_VALIDATORSET(state, validatorSet) {
        state.validatorSet = validatorSet;
    }
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})