<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import { languages } from "@/i18n.js";

export default {
  components: {
    FadeTransition,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Radix Node - AllesOverCrypto";
      },
    },
  },
  mounted() {
    let fontawesomeScript = document.createElement('script')
    fontawesomeScript.setAttribute('src', 'https://kit.fontawesome.com/54e328c040.js')
    document.head.appendChild(fontawesomeScript)
  },
  created() {
    if(this.$route.query.hl && languages.some(language => language.code === this.$route.query.hl)) {
      this.$i18n.locale = this.$route.query.hl;
    }
  },
};
</script>
