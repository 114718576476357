/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import Argon from "./plugins/argon-kit";
import Flag from 'vue-flagpack';

import './registerServiceWorker';

import router from "./router";
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(Argon);
Vue.use(Flag, {
  name: 'Flag'
}) 

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
