<template>
  <svg :key="uptimeData" width="100%" height="100%" viewBox="0 0 1000 100">
    <g class="container">
      <g v-for="value, index in uptimeData" :key="index">
        <rect id="bar-id-0" v-bind:fill="[ value != null ? downtimeColor : noDataColor ]" :x="index * (barWidth + spacing)" y="0" :width="barWidth" height="100" :rx="barWidth / 2" :ry="barWidth / 2">
          <animate attributeName="fill" :from="noDataColor" :to="downtimeColor" :dur="value * 500 + 'ms'" fill="freeze"></animate>
          <title>{{ value ? (value * 100).toFixed(2) + '%' : $t('general.no-data') }}</title>
        </rect>
        <rect id="bar-id-0" :fill="uptimeColor" :x="index * (barWidth + spacing)" y="75" :width="barWidth" height="0" :rx="barWidth / 2" :ry="barWidth / 2">
          <animate attributeName="height" from="0" :to="value * 100" :dur="value * 1000 + 'ms'" fill="freeze"></animate>
          <animate attributeName="y" from="100" :to="100 - value * 100" :dur="value * 1000 + 'ms'" fill="freeze"></animate>
          <title>{{ (value * 100).toFixed(2) }}%</title>
        </rect>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "uptime-bars",
  props: {
    noDataColor: {
      type: String,
      default: "#bbb",
      description: "Color for bars with no data"
    },
    downtimeColor: {
      type: String,
      default: "#f5365c",
      description: "Color for downtime"
    },
    uptimeColor: {
      type: String,
      default: "#2dce89",
      description: "Color for uptime"
    },
    spacing: {
      type: Number,
      default: 5,
      description: "Spacing between bars"
    },
    uptimeData: {
      type: Array,
      default: [null, 0, 0.5, 1],
      description: "Array with uptime data. 0-1 for uptime, null for no data."
    }
  },
  computed: {
    barCount() {
      return this.uptimeData.length;
    },
    barWidth() {
      return (1000 - this.spacing * (this.barCount - 1)) / this.barCount;
    }
  }
};
</script>
<style>
</style>