<template>
  <div class="locale-switcher">
    <BaseDropdown position="right">
      <base-button slot="title" type="neutral" class="dropdown-toggle">
        <Flag :code="getFlagCode($i18n.locale)" :hasBorder="false" :hasBorderRadius="true" size="S" />
        &nbsp;{{ $i18n.locale }}
      </base-button>
      <li v-for="language in languages" v-bind:key="language.code">
        <a class="dropdown-item" href="#" @click="$i18n.locale = language.code">
          <Flag :code="getFlagCode(language.code)" :hasBorder="false" :hasBorderRadius="true" size="S" />
          &nbsp;<b>{{ language.name }}</b>
        </a>
      </li>
    </BaseDropdown>
  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
import { languages }  from '@/i18n.js';

export default {
  components: {
    BaseDropdown,
  },
  methods: {
    getFlagCode: function(langcode) {
      if (langcode == 'en') return 'GB-UKM';
      return langcode.toUpperCase();
    },
    getLanguageName: function(langcode) {
      return this.languages.find(({code}) => code == langcode).name;
    }
  },
  computed: {
    languages: function () {
      return languages;
    }
  }
};
</script>