<template>
  <header class="header-global">
    <base-nav class="navbar-main" transparent type="" effect="light" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img src="img/brand/white.png" alt="logo" />
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="#">
            <img src="img/brand/blue.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li class="nav-item">
          <router-link
            class="nav-link"
            to="/stats"
          >
            <span class="nav-link-inner--text">{{ $t('header.link.stats') }}</span>
          </router-link>
        </li>
        <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
          <a
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">{{ $t('general.allesovercrypto') }}</span>
          </a>
          <div class="dropdown-menu-inner">
            <a href="https://allesovercrypto.nl/" target="_blank" class="media d-flex align-items-center">
              <div
                class="
                  icon icon-shape
                  bg-gradient-primary
                  rounded-circle
                  text-white
                "
              >
                <i class="ni ni-spaceship"></i>
              </div>
              <div class="media-body ml-3">
                <h6 class="heading text-primary mb-md-1">{{ $t('general.allesovercrypto') }}</h6>
                <p class="description d-none d-md-inline-block mb-0">
                  {{ $t('header.allesovercrypto.description') }}
                </p>
              </div>
            </a>
            <a href="https://www.cryptomasterclass.nl/" target="_blank" class="media d-flex align-items-center">
              <div
                class="
                  icon icon-shape
                  bg-gradient-warning
                  rounded-circle
                  text-white
                "
              >
                <i class="ni ni-ui-04"></i>
              </div>
              <div class="media-body ml-3">
                <h5 class="heading text-warning mb-md-1">{{ $t('general.cryptomasterclass') }}</h5>
                <p class="description d-none d-md-inline-block mb-0">
                  {{ $t('header.cryptomasterclass.description') }}
                </p>
              </div>
            </a>
          </div>
        </base-dropdown>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://twitter.com/allesovercrypto"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            :title="$t('general.follow-us-on', ['Twitter'])"
          >
            <i class="fa fa-twitter-square"></i>
            <span class="nav-link-inner--text d-lg-none">Twitter</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/allesovercrypto"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            :title="$t('general.follow-us-on', ['Facebook'])"
          >
            <i class="fa fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/allesovercrypto"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            :title="$t('general.follow-us-on', ['Instagram'])"
          >
            <i class="fa fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>
        <li class="nav-item d-none d-lg-block ml-lg-4">
          <router-link
            to="/#staking"
            rel="noopener"
            class="btn btn-primary btn-icon"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-play mr-2"></i>
            </span>
            <span class="nav-link-inner--text">{{
              $t("header.button.cta")
            }}</span>
          </router-link>
        </li>
      </ul>
      <LocaleSwitcher />
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
    LocaleSwitcher,
  },
};
</script>
<style>
</style>
