<template>
  <svg version="1.1" id="Ñëîé_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 1200 1200" style="enable-background:new 0 0 1200 1200;" xml:space="preserve">
  <g class="vector-sharing-st1">
    <path class="vector-sharing-st2" d="M1200,635.74v203.82l-48.03-14.49l3.56-11.78l-24.94-7.55l1.2-3.94c-20.76-3.94-48.18-12.14-73.25-23.53
      c-23.86,22.87-40.53,15.57-79.34-11.46c-0.01-0.01-0.01-0.01-0.01-0.01c-10.66-3.98-17.71-11.12-23.73-14.87
      c-2.5-1.56-5.59-3.79-8.79-6.24c-0.01,0-0.01,0-0.01,0c-9.26-7.06-12.31-10.16-15.05-11.91c-11.55,44.51-34.03,85.29-65.05,118.65
      c-24.71-24.73-182.96-182.97-205.79-205.8l69.9-260.89c42.99,13.21,82.28,36.83,114.14,68.68c24.87,24.87,55.08,54.14,76.76,109.65
      c35.52-5.55,71.62-9.33,83.14-6.57c21.35,5.14,156.67,71.43,176.5,81.15l2.38-7.87h0.01L1200,635.74z"/>
    <path class="vector-sharing-st2" d="M711.22,380.43l-69.91,260.89l-260.88,69.91l-10.37-10.38c-15.04-65.56-6.01-136.46,29.13-198.23
      c-1,0.15-36.19,4.7-43.75,3.42c-13.14-2.24-15.48-3.1-15.48-3.1c-5.64-0.67-12.06-1.53-17.5-2.43c0-0.01,0-0.01,0-0.01
      c-3.98-0.66-7.72-1.38-10.57-2.14c-6.87-1.82-16.91-2.08-27.09-7.11c-46.3-9.78-63.02-16.94-62.69-49.98
      c-23.64-9.72-48.08-23.29-67.3-37.27l-2.06,3.57l-22.58-13l-6.14,10.67L0,333.83V96.03l227.55,129.45l-6.14,10.66l22.56,13h0.01
      l-4.11,7.13c21.04,7.94,70.64,26.76,112.75,43.62c0,0,0,0,0,0.01c32.09,12.85,59.82,24.56,67.25,29.43
      c11.77,7.74,40.62,47.16,63.39,81.92c63.33-43.33,142.19-58.5,217.59-41.19L711.22,380.43z"/>
    <path class="vector-sharing-st2" d="M652.27,939.37c1.5,8.05,1.79,12.28,1.81,12.55c1.29,5.48,2.68,11.83,3.7,17.29c0,0,0,0-0.01,0
      c0.75,3.97,1.34,7.73,1.61,10.67c0.63,7.09,3.83,16.51,2.58,27.89c6.64,46.83,5.65,65-25.52,76c-1.09,26.38-5.67,53.95-12.01,75.99
      l4.06,0.71l-4.5,25.66l12.13,2.13l-2.07,11.74H423.42l8.38-47.53l12.12,2.12l4.49-25.65h0.01v-0.01l8.1,1.42
      c0.25-22.01,2.06-172.83,7.09-194.13c2.2-9.27,15.34-30.84,31.31-54.13c-16.52-12.73-25.33-22.1-40.5-37.28
      c-31.86-31.85-55.47-71.15-68.68-114.14l260.89-69.9c7.75,7.75,197.16,197.15,204.93,204.92
      C799.02,914.61,727.72,942.47,652.27,939.37z"/>
  </g>
  <g>
    <g>
      <g>
        <g>
          <path class="vector-sharing-st3" d="M1011.05,726.57c-24.96,25.3-41.4,18.56-81.47-9.38c-10.67-3.99-17.71-11.13-23.73-14.88
            c-6.27-3.9-16.17-11.96-22.57-17.33c-5.41-3.33-10.04-7.02-12.85-9.16c-5.79-4.39-24-29.38-31.27-39.5
            c-5.17,15.06-16.54,23.68-27.33,20.38c-8.3-2.55-18.86-28.05-25.76-47.3c13.41,7.9,40.57,18.25,48.41,20.71
            c2.99,0.95,41.64,16.75,45.59,18.4c33.64,14.01,57.17,29.21,57.17,29.21C960.36,699.79,986.65,715.49,1011.05,726.57z"/>
          <g>
            <path class="vector-sharing-st4" d="M807.49,653.59c0.99-1.33,1.99-2.98,2.4-4.63c0.93-3.73-3.88-16-5.98-18.05c-1.74-1.71-7.53-4.95-12-6.08
              C795,633.99,799.88,647.53,807.49,653.59z"/>
            <path class="vector-sharing-st5" d="M974.24,716.24c10.33,6.32,20.6,11.69,30.39,16.23c1.4-1.15,2.83-2.42,4.29-3.82
              c-24.39-11.08-50.67-26.77-73.79-48.83c0,0-23.52-15.2-57.17-29.21c-3.94-1.64-42.59-17.46-45.59-18.4
              c-7.2-2.27-30.64-11.16-44.82-18.71c0.89,2.41,1.83,4.89,2.8,7.37c16.05,7.82,37.28,15.4,40.42,16.39
              c0.66,0.21,3.27,1.23,6.94,2.69c0.53-1.16,1-2.38,1.43-3.63c1.18,1.64,2.64,3.67,4.29,5.94c12.12,4.88,28.95,11.76,32.48,13.23
              c23.16,9.64,41.6,19.95,50.51,25.21c-5.06,3.49-10.62,7.06-15.61,9.63c-8.37,4.31-18.95-0.06-27.55-5.36
              c3.84,3.23,8.94,7.41,13.77,11.09c5.99,1.33,11.35,0.82,15.94-1.55c5.79-2.98,12.25-7.19,17.87-11.13
              c0.43,0.26,0.76,0.47,1,0.62c12.1,11.49,25.04,21.25,37.99,29.49c-4.26,2.75-9.14,4.84-14.65,5.76
              c-10.49,1.75-18.8,0.47-25.63-2.08c2.83,1.97,6.63,4.57,10.86,7.31c4.96,0.5,10.16,0.3,15.54-0.59
              C963,722.74,969.08,719.9,974.24,716.24z"/>
          </g>
        </g>
        <g>
          <path class="vector-sharing-st6" d="M1037.49,543.71l-33.55,50.11c-86.59,7.65-136.99,2.62-224.76-31.54l-0.22-7.36
            c-0.13-4.5,0.85-9,3.03-12.94c2.24-4.05,5.49-7.61,9.34-9.8c14.75-8.4,140.29-29.91,163.76-24.29
            C965.08,510.27,1000.31,526.2,1037.49,543.71z"/>
          <path class="vector-sharing-st7" d="M972.13,585.93c-2.81,0.44-5.88,0.83-9.29,1.16c-31.5,3.03-65.66-0.53-96.76-12.67
            c-1.72-0.67-12.44-4.34-24.14-8.32c-1.99-4.66-2.21-8.05-0.2-9.4c12.98-8.7,37.95-14.4,76.12-5.28
            c38.16,9.13,54.2,33.95,54.2,33.95S972.1,585.56,972.13,585.93z"/>
          <path class="vector-sharing-st5" d="M972.99,583.4c-22.45,2.07-40.44,2.28-55.95,0.56c6.34-24.35,10.7-26.7,19.44-46.37
            c-10.6,13.37-18.74,29.23-24.46,45.73c-14.47-2.07-33.4-8-42.63-11.51c1.43-5.68,2.78-11.39,4.08-17.16
            c-0.42,1.33-5.47,15.81-5.73,16.55c-3.67-1.38-30.55-9.63-43.42-14.58c-21.33-8.2-31.49-11.45-45.26,1.46l0.11,3.56
            c7.89-6.46,18.99-8.85,29.58-4.97c0,0,53.29,17.98,57.34,19.56c31.1,12.14,65.26,15.7,96.76,12.67
            c10.89-1.05,18.33-2.76,24.91-4.41C982.84,583.32,977.87,582.95,972.99,583.4z"/>
        </g>
      </g>
      <path class="vector-sharing-st8" d="M625.96,611.82l69.9-260.89c42.99,13.21,82.29,36.83,114.14,68.68c34.22,34.22,58.92,77.01,71.45,123.75
        c12.52,46.74,12.52,96.15,0,142.89c-11.66,43.52-33.88,83.61-64.51,116.55L625.96,611.82z"/>
      <path class="vector-sharing-st9" d="M611.15,597.01l69.9-260.89c42.99,13.21,82.29,36.83,114.14,68.68c34.22,34.22,58.92,77.01,71.45,123.75
        c12.52,46.74,12.52,96.15,0,142.89c-11.66,43.52-33.88,83.61-64.51,116.55L611.15,597.01z"/>
      <g>
        <path class="vector-sharing-st6" d="M1141.42,593.87l-48.51,160.02c0,0-91.76-11.06-157.78-74.07c0,0-23.52-15.2-57.17-29.21
          c-3.94-1.64-42.6-17.45-45.59-18.4c-9.49-2.98-47.23-17.5-54.72-25.25c-14.82-15.34-10.75-35.07,1.51-45.34v0.02
          c7.89-6.46,18.99-8.85,29.58-4.97c0,0,53.29,17.98,57.34,19.56c31.1,12.14,65.26,15.71,96.76,12.67
          c7.8-0.75,13.83-1.84,19.04-3.01c24.15-2.69,34.5-36.32,40.71-49.17C1076.39,561.77,1141.42,593.87,1141.42,593.87z"/>
        <g>
          <path class="vector-sharing-st4" d="M820.56,597.15c-3.59,5.39-13.14,12.2-38.16,4.66c0,0-10.37-9.38-5.53-24.44
            c4.84-15.07,18.73-16.65,18.73-16.65c28.99,9.91,29.22,23.45,27.91,28.92C823.15,591.19,822.36,594.44,820.56,597.15z"/>
          <path class="vector-sharing-st10" d="M823.51,589.65c-0.36,1.54-1.15,4.8-2.95,7.5c-1.4,2.1-3.7,4.42-7.47,6.02c0.23-4.05,1.08-8.62,2.58-13.3
            c1.5-4.72,3.46-8.96,5.64-12.41C824.42,582.51,824.14,587.01,823.51,589.65z"/>
          <path class="vector-sharing-st10" d="M795.6,560.72c0,0-13.89,1.58-18.73,16.65c-4.84,15.07,5.52,24.44,5.52,24.44
            c0.61,0.18,1.21,0.36,1.8,0.52c-0.8-0.71-10.91-10.14-6.26-24.62c4.6-14.3,17.96-16.26,19.31-16.41
            C796.71,561.1,796.16,560.91,795.6,560.72z"/>
        </g>
        <path class="vector-sharing-st11" d="M1128.99,613.53c-0.38,1.16-15.4,51.83-48.73,83.99c-26.73,25.78-74,13.59-89.36,5.74
          c-18.08-9.25-36.46-21.06-53.36-36.13c0,0-24.02-14.39-58.13-27.24c-4-1.51-43.17-15.99-46.19-16.83
          c-9.58-2.66-47.79-15.87-55.55-23.37c-5.12-4.94-8.14-10.44-9.48-15.97c-0.32,7.76,2.46,16,9.48,23.25
          c7.5,7.76,45.23,22.27,54.72,25.25c2.99,0.95,41.64,16.75,45.59,18.4c33.64,14.01,57.17,29.21,57.17,29.21
          c31.9,30.44,69.81,48.76,100.48,59.56c0,0,32.25,11.48,57.3,14.51L1128.99,613.53z"/>
      </g>
      <g>
        <path class="vector-sharing-st12" d="M1165.08,590.58c-2.32,7.67-50.65,167.28-52.98,174.97l-31.12-9.42l53-174.96L1165.08,590.58z"/>
        <polygon class="vector-sharing-st0" points="1165.08,590.58 1138.59,678.06 1107.48,668.64 1133.97,581.16 			"/>
        <polygon class="vector-sharing-st13" points="1102.35,775.46 1200,804.91 1200,588.26 1162.47,576.93 			"/>
        <polygon class="vector-sharing-st14" points="1132.41,676.19 1200,696.61 1200,588.26 1162.47,576.93 			"/>
      </g>
    </g>
    <g>
      <g>
        <g>
          <path class="vector-sharing-st3" d="M172.56,388.68c-1.35,35.51,14.82,42.88,62.62,52.95c10.21,5.04,20.24,5.29,27.09,7.11
            c7.13,1.9,19.79,3.59,28.08,4.58c6.13,1.66,11.99,2.51,15.47,3.1c7.17,1.22,37.85-2.63,50.2-4.26
            c-7.43,14.08-5.93,28.27,3.86,33.87c7.54,4.31,33.36-5.46,52.11-13.61c-14.93-4.36-41.07-17.07-48.24-21.1
            c-2.74-1.53-40.72-18.88-44.62-20.63c-33.25-14.93-60.43-21.66-60.43-21.66C226.78,407.29,197.34,398.88,172.56,388.68z"/>
          <g>
            <path class="vector-sharing-st4" d="M365.13,487.06c0.3-1.63,0.81-3.49,1.73-4.92c2.08-3.23,14.32-8.13,17.24-8c2.44,0.1,8.76,2.1,12.64,4.58
              C387.97,482.74,374.76,488.46,365.13,487.06z"/>
            <path class="vector-sharing-st5" d="M205.29,408.43c-11.68-3.2-22.63-7.01-32.64-11.03c-0.12-1.81-0.17-3.72-0.15-5.74
              c24.77,10.19,54.2,18.59,86.12,20.34c0,0,27.18,6.74,60.43,21.67c3.9,1.75,41.89,19.09,44.62,20.63
              c6.58,3.69,29.11,14.69,44.32,19.85c-2.37,1-4.81,2.02-7.29,3.01c-16.69-6.34-36.75-16.63-39.62-18.24
              c-0.6-0.33-3.14-1.54-6.71-3.21c0.48-1.18,1.05-2.35,1.67-3.53c-2,0.26-4.48,0.59-7.27,0.94
              c-11.86-5.49-28.39-13.05-31.88-14.61c-22.89-10.27-43.02-16.66-52.96-19.55c0.92,6.08,2.13,12.57,3.68,17.97
              c2.59,9.05,13.01,13.77,22.77,16.41c-4.98-0.59-11.53-1.44-17.51-2.44c-5.08-3.45-8.37-7.71-9.79-12.68
              c-1.79-6.26-3.15-13.85-4.13-20.65c-0.48-0.13-0.86-0.23-1.13-0.3c-16.66-0.95-32.63-3.7-47.51-7.49
              c0.92,4.99,2.73,9.97,5.83,14.62c5.91,8.84,12.53,14.02,19.06,17.25c-3.38-0.71-7.87-1.71-12.76-2.9
              c-3.76-3.27-7.17-7.2-10.21-11.73C208.25,421.08,206.16,414.7,205.29,408.43z"/>
          </g>
        </g>
        <g>
          <path class="vector-sharing-st6" d="M287.72,244.2l-13.55,58.76c53.71,68.35,91.89,101.63,176.89,142.21l5.52-4.88
            c3.37-2.99,5.97-6.78,7.35-11.07c1.42-4.41,1.79-9.21,0.75-13.52c-3.98-16.5-74.26-122.72-94.43-135.98
            C361.67,274.08,325.94,259.31,287.72,244.2z"/>
          <path class="vector-sharing-st7" d="M301.71,320.74c1.61,2.35,3.42,4.85,5.52,7.56c19.36,25.03,45.35,47.49,75.49,61.84
            c1.67,0.8,11.68,6.09,22.59,11.9c4.75-1.74,7.38-3.9,6.99-6.29c-2.54-15.42-15.49-37.52-48.28-59.09
            c-32.78-21.55-61.85-16.25-61.85-16.25S302,320.51,301.71,320.74z"/>
          <path class="vector-sharing-st5" d="M302.96,318.39c13.86,17.78,26.03,31.03,37.91,41.16c13.4-21.3,12.14-26.08,20.48-45.92
            c-2.49,16.88-8.47,33.67-16.58,49.14c11.42,9.13,28.69,18.86,37.58,23.19c3.16-4.93,6.4-9.83,9.71-14.73
            c-0.69,1.22-7.78,14.81-8.14,15.5c3.52,1.73,27.93,15.67,40.35,21.66c20.58,9.93,29.9,15.11,29.93,33.98l-2.67,2.36
            c-0.69-10.18-6.55-19.9-16.63-24.96c0,0-49.6-26.52-53.52-28.39c-30.14-14.36-56.13-36.81-75.49-61.84
            c-6.69-8.66-10.54-15.25-13.85-21.18C296.27,311.15,299.95,314.52,302.96,318.39z"/>
        </g>
      </g>
      <path class="vector-sharing-st15" d="M651.23,320.44l-12.79,6.09c-12.77-1.8-25.66-2.72-38.57-2.72c-36.19,0-71.53,6.98-105.03,20.76
        c-33.9,13.94-64.23,34.16-90.17,60.09c-34.22,34.22-58.93,77.01-71.45,123.75c-10.04,37.46-12.02,76.64-5.97,114.78l-6.81,8.04
        l10.37,10.37L591.7,591.7l69.91-260.89L651.23,320.44z"/>
      <path class="vector-sharing-st16" d="M320.44,651.24c-10.05-43.84-9.25-89.68,2.41-133.19c12.52-46.74,37.23-89.53,71.45-123.75
        c25.94-25.94,56.27-46.15,90.17-60.09c33.5-13.78,68.84-20.76,105.03-20.76c20.76,0,41.48,2.35,61.73,7l-69.91,260.89
        L320.44,651.24z"/>
      <g>
        <g>
          <path class="vector-sharing-st6" d="M180,202.8L96.6,347.72c0,0,70.9,59.3,162.02,64.27c0,0,27.18,6.74,60.43,21.66
            c3.9,1.75,41.89,19.09,44.62,20.63c8.67,4.87,45.09,22.44,55.87,22.59c21.33,0.3,32.92-16.18,32.01-32.15l-0.02,0.02
            c-0.69-10.17-6.55-19.9-16.63-24.96c0,0-49.6-26.53-53.52-28.39c-30.14-14.35-56.13-36.81-75.49-61.84
            c-4.8-6.2-8.13-11.34-10.85-15.93c-14.58-19.45,2.85-50.01,7.96-63.34C247.92,228.22,180,202.8,180,202.8z"/>
          <g>
            <path class="vector-sharing-st4" d="M397.31,438.89c-1.47,6.31,0.1,17.93,22.73,31.01c0,0,13.93,1.14,21.6-12.71
              c7.66-13.84-0.69-25.05-0.69-25.05c-27.07-14.35-37.1-5.24-40.19-0.54C399.89,432.92,398.05,435.72,397.31,438.89z"/>
            <path class="vector-sharing-st10" d="M400.76,431.6c-0.88,1.32-2.71,4.12-3.45,7.29c-0.58,2.46-0.69,5.72,0.73,9.56
              c2.8-2.94,5.54-6.7,7.93-10.99c2.42-4.33,4.16-8.65,5.18-12.61C405.34,426.06,402.25,429.34,400.76,431.6z"/>
            <path class="vector-sharing-st10" d="M440.95,432.14c0,0,8.35,11.2,0.69,25.05c-7.67,13.85-21.6,12.71-21.6,12.71
              c-0.55-0.32-1.09-0.64-1.61-0.95c1.06,0.1,14.86,1.01,22.23-12.3c7.28-13.14-0.45-24.22-1.26-25.31
              C439.92,431.59,440.43,431.86,440.95,432.14z"/>
          </g>
          <path class="vector-sharing-st11" d="M174.18,225.33c-0.59,1.07-27.23,46.72-27.84,93.03c-0.49,37.13,40.77,63.24,57.01,69.05
            c19.12,6.84,40.32,12.15,62.87,14.15c0,0,26.94,7.66,59.65,23.71c3.84,1.89,41.21,20.51,43.9,22.14
            c8.5,5.17,44.29,23.96,55.07,24.48c7.1,0.35,13.19-1.22,18.13-4.03c-5.43,5.55-13.34,9.16-23.43,9.02
            c-10.79-0.15-47.2-17.72-55.87-22.59c-2.74-1.53-40.72-18.88-44.62-20.63C285.8,418.73,258.61,412,258.61,412
            c-44.03-2.4-83.34-17.49-112.21-32.44c0,0-30.45-15.65-49.81-31.83L174.18,225.33z"/>
        </g>
        <g>
          <path class="vector-sharing-st12" d="M166.2,183.31c-4,6.94-87.23,151.45-91.24,158.42l28.18,16.23l91.22-158.43L166.2,183.31z"/>
          <polygon class="vector-sharing-st0" points="166.2,183.31 120.58,262.52 148.75,278.74 194.36,199.53 				"/>
          <polygon class="vector-sharing-st13" points="177.93,175.86 74.41,355.62 0,312.66 0,73.14 				"/>
          <polygon class="vector-sharing-st14" points="177.93,175.86 126.18,265.74 0,192.9 0,73.14 				"/>
        </g>
      </g>
    </g>
    <g>
      <g>
        <g>
          <path class="vector-sharing-st3" d="M584,1035.1c33.83-10.88,35.23-28.59,28.34-76.95c1.25-11.32-1.95-20.83-2.58-27.89
            c-0.66-7.35-3.39-19.83-5.3-27.96c-0.54-6.33-1.75-12.12-2.38-15.6c-1.31-7.15-15.41-34.67-21.17-45.72
            c15.77,2.17,28.6-4.1,30.51-15.21c1.47-8.56-16.54-29.48-30.61-44.31c1.01,15.53-1.99,44.44-3.33,52.55
            c-0.5,3.1-3.81,44.72-4.12,48.99c-2.66,36.35,0.31,64.19,0.31,64.19C582.95,977.79,585.11,1008.33,584,1035.1z"/>
          <g>
            <path class="vector-sharing-st4" d="M610.59,820.5c-1.64,0.28-3.56,0.43-5.21,0.06c-3.75-0.85-12.54-10.67-13.42-13.47
              c-0.74-2.33-1.02-8.95-0.02-13.45C598.72,800.51,608.61,810.97,610.59,820.5z"/>
            <path class="vector-sharing-st5" d="M591.37,997.6c0.99,12.07,1.15,23.66,0.8,34.44c-1.66,0.73-3.44,1.43-5.35,2.11
              c1.1-26.76-1.07-57.3-10.34-87.88c0,0-2.97-27.85-0.31-64.2c0.31-4.26,3.61-45.89,4.12-48.99c1.22-7.45,3.85-32.38,3.5-48.44
              c1.75,1.88,3.54,3.83,5.32,5.82c-0.25,17.85-3.06,40.22-3.59,43.47c-0.11,0.68-0.37,3.47-0.72,7.41
              c-1.27-0.05-2.57-0.18-3.89-0.36c0.93,1.79,2.08,4.01,3.37,6.51c-1.11,13.02-2.55,31.15-2.82,34.96
              c-1.83,25.02-0.94,46.13-0.26,56.46c5.4-2.94,11.08-6.3,15.63-9.6c7.62-5.53,8.49-16.94,7.63-27.01
              c1.15,4.89,2.59,11.33,3.7,17.29c-1.51,5.95-4.39,10.5-8.57,13.53c-5.27,3.82-11.94,7.7-17.99,10.94
              c0.04,0.5,0.08,0.89,0.1,1.17c4.81,15.98,7.68,31.93,9.21,47.2c4.38-2.57,8.44-5.98,11.74-10.48
              c6.29-8.58,8.89-16.57,9.69-23.81c0.48,3.42,1.09,7.98,1.64,12.99c-1.79,4.65-4.31,9.2-7.53,13.61
              C602.24,990.49,596.96,994.64,591.37,997.6z"/>
          </g>
        </g>
        <g>
          <path class="vector-sharing-st6" d="M408.85,976.31l59.85-7.36c45.85-73.85,64.07-121.11,73.13-214.86l-6.48-3.52
            c-3.96-2.15-8.41-3.29-12.92-3.12c-4.63,0.17-9.27,1.47-12.96,3.92c-14.15,9.38-89.92,111.75-95.48,135.24
            C411.63,896.6,409.97,935.22,408.85,976.31z"/>
          <path class="vector-sharing-st7" d="M475.99,936.98c1.66-2.31,3.39-4.88,5.22-7.77c16.9-26.75,29.11-58.85,32.29-92.09
            c0.18-1.84,1.73-13.06,3.45-25.3c-3.26-3.87-6.19-5.6-8.3-4.42c-13.62,7.66-29.96,27.39-39.01,65.57
            c-9.04,38.18,5.88,63.68,5.88,63.68S475.67,936.79,475.99,936.98z"/>
          <path class="vector-sharing-st5" d="M473.35,936.61c11.97-19.11,20.26-35.07,25.71-49.7c-24.59-5.31-28.66-2.48-50.15-3.54
            c16.71-3.44,34.54-3.56,51.85-1.23c4.67-13.85,7.91-33.42,8.94-43.25c-5.71-1.28-11.42-2.65-17.16-4.09
            c1.38,0.23,16.58,2.25,17.35,2.34c0.42-3.89,5.17-31.61,6.55-45.33c2.29-22.74,3.97-33.27,21.7-39.74l3.13,1.7
            c-9.33,4.13-16.46,12.96-17.77,24.16c0,0-7.96,55.68-8.38,60c-3.18,33.23-15.39,65.34-32.29,92.09
            c-5.85,9.25-10.72,15.12-15.16,20.26C468.84,945.37,470.75,940.76,473.35,936.61z"/>
        </g>
      </g>
      <path class="vector-sharing-st17" d="M613.92,889.99c-24.09,0-48.12-3.17-71.44-9.41c-46.74-12.52-89.53-37.23-123.74-71.45
        c-31.86-31.86-55.47-71.15-68.68-114.14l260.89-69.9l190.99,190.98c-24.3,22.63-52.17,40.49-82.98,53.16
        C685.46,883.01,650.12,889.99,613.92,889.99z"/>
      <path class="vector-sharing-st18" d="M599.98,876.05c-24.09,0-48.12-3.17-71.44-9.41c-46.74-12.52-89.53-37.23-123.74-71.45
        c-31.86-31.86-55.47-71.15-68.68-114.14l260.89-69.9L788,802.13c-24.3,22.63-52.17,40.49-82.98,53.16
        C671.52,869.07,636.18,876.05,599.98,876.05z"/>
      <g>
        <g>
          <path class="vector-sharing-st6" d="M406.79,1091.69l164.7,28.81c0,0,31.48-86.9,4.99-174.23c0,0-2.97-27.85-0.31-64.19
            c0.31-4.26,3.62-45.89,4.12-48.99c1.61-9.82,5.66-50.04,2.12-60.23c-7.01-20.15-26.46-25.4-41.16-19.08l0.02,0.01
            c-9.32,4.13-16.46,12.96-17.77,24.16c0,0-7.96,55.68-8.38,60c-3.18,33.23-15.39,65.33-32.29,92.09
            c-4.18,6.63-7.88,11.51-11.26,15.64c-13.29,20.35-47.97,14.43-62.24,14.19C407.44,1019.17,406.79,1091.69,406.79,1091.69z"/>
          <g>
            <path class="vector-sharing-st4" d="M554.31,806.74c6.43-0.77,16.81-6.23,21.36-31.97c0,0-3.7-13.48-19.33-15.95
              c-15.63-2.47-23.3,9.22-23.3,9.22c-4.22,30.35,7.77,36.65,13.24,37.95C547.82,806.36,551.08,807.12,554.31,806.74z"/>
            <path class="vector-sharing-st10" d="M546.28,805.99c1.54,0.37,4.8,1.13,8.03,0.74c2.51-0.3,5.61-1.31,8.74-3.95
              c-3.72-1.63-8.19-2.92-13.04-3.7c-4.89-0.79-9.56-0.95-13.62-0.56C539.51,803.58,543.65,805.36,546.28,805.99z"/>
            <path class="vector-sharing-st10" d="M533.04,768.04c0,0,7.67-11.68,23.3-9.22c15.63,2.47,19.33,15.95,19.33,15.95
              c-0.12,0.63-0.23,1.24-0.35,1.84c-0.27-1.03-4.14-14.31-19.16-16.68c-14.84-2.34-22.61,8.71-23.35,9.84
              C532.88,769.2,532.96,768.62,533.04,768.04z"/>
          </g>
          <path class="vector-sharing-st11" d="M429.95,1089.45c1.2,0.18,53.21,9.61,96.94-5.66c35.06-12.24,45.48-59.94,45.39-77.19
            c-0.11-20.31-2.37-42.04-8.2-63.92c0,0-2.02-27.93,1.88-64.17c0.46-4.25,5.18-45.74,5.79-48.82c1.95-9.75,7.37-49.82,4.17-60.12
            c-2.11-6.79-5.65-11.98-9.99-15.66c7.07,3.21,13.17,9.4,16.49,18.94c3.55,10.19-0.5,50.41-2.12,60.23
            c-0.5,3.1-3.81,44.72-4.12,48.99c-2.66,36.35,0.31,64.19,0.31,64.19c12.8,42.2,12.07,84.29,7.89,116.53
            c0,0-4.29,33.96-12.87,57.69L429.95,1089.45z"/>
        </g>
        <g>
          <path class="vector-sharing-st12" d="M393.19,1111.32c7.89,1.38,172.15,30.17,180.07,31.55l5.61-32.03l-180.08-31.53L393.19,1111.32z"/>
          <polygon class="vector-sharing-st0" points="393.19,1111.32 483.23,1127.1 488.83,1095.08 398.8,1079.31 				"/>
          <polygon class="vector-sharing-st13" points="586.5,1138.64 575.68,1200 365.05,1200 382.18,1102.85 				"/>
          <polygon class="vector-sharing-st14" points="484.34,1120.74 470.37,1200 365.05,1200 382.18,1102.85 				"/>
        </g>
      </g>
    </g>
  </g>
  </svg>
</template>
<script>
export default {
  name: "vector-sharing",
  props: {
    tag: {
      type: String,
      default: "button",
      description: "Button tag (default -> button)"
    },
    type: {
      type: String,
      default: "default",
      description: "Button type (e,g primary, danger etc)"
    },
    size: {
      type: String,
      default: "",
      description: "Button size lg|sm"
    },
    textColor: {
      type: String,
      default: "",
      description: "Button text color (e.g primary, danger etc)"
    },
    nativeType: {
      type: String,
      default: "button",
      description: "Button native type (e.g submit,button etc)"
    },
    icon: {
      type: String,
      default: "",
      description: "Button icon"
    },
    text: {
      type: String,
      default: "",
      description: "Button text in case not provided via default slot"
    },
    outline: {
      type: Boolean,
      default: false,
      description: "Whether button style is outline"
    },
    rounded: {
      type: Boolean,
      default: false,
      description: "Whether button style is rounded"
    },
    iconOnly: {
      type: Boolean,
      default: false,
      description: "Whether button contains only an icon"
    },
    block: {
      type: Boolean,
      default: false,
      description: "Whether button is of block type"
    }
  },
  computed: {
    classes() {
      let btnClasses = [
        { "btn-block": this.block },
        { "rounded-circle": this.rounded },
        { "btn-icon-only": this.iconOnly },
        { [`text-${this.textColor}`]: this.textColor },
        { "btn-icon": this.icon || this.$slots.icon },
        this.type && !this.outline ? `btn-${this.type}` : "",
        this.outline ? `btn-outline-${this.type}` : ""
      ];
      if (this.size) {
        btnClasses.push(`btn-${this.size}`);
      }
      return btnClasses;
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    }
  }
};
</script>